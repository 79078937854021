import { Spring, animated, config } from 'react-spring';

import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  render() {
    const { location } = this.props;
    return (
      <Layout location={location}>
        <SEO
          title="Home"
          keywords={[
            `web developer`,
            `web development`,
            `website developer`,
            `huntsville, al`,
            `huntsville`,
            `huntsville web developer`,
            `huntsville website developer`
          ]}
        />
        <h1 className="self-center max-w-xl px-2 text-5xl font-sans font-bold text-black">
          <Spring
            native
            config={{ ...config.molasses, delay: 1000 }}
            from={{ opacity: 0, transform: 'translateY(-30px)' }}
            to={{ opacity: 1, transform: 'translateY(0px)' }}
          >
            {props => (
              <animated.div className="font-serif font-normal" style={props}>
                Handcrafted
              </animated.div>
            )}
          </Spring>
          <Spring
            native
            config={{ ...config.molasses, delay: 1000 }}
            from={{ opacity: 0, transform: 'translateX(20px)' }}
            to={{ opacity: 1, transform: 'translateX(0px)' }}
          >
            {props => (
              <animated.div style={props}>
                modern <span className="no-wrap">web design</span>
              </animated.div>
            )}
          </Spring>
        </h1>
      </Layout>
    );
  }
}
